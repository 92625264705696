import $axios from '@/utils/request'

// 获取课程用户（限定课程）
export function getCourseStudentListWithTaskStatus (data) {
  const url = '/course/user/getCourseStudentListWithTaskStatus'
  return $axios.fGet(url, data)
}

// 查询全部校区
export function getCampusAll (data) {
  const url = '/campus/getCampusAll'
  return $axios.fPost(url, data)
}

// 获取全部班级列表
export function getClassAll (data) {
  const url = '/class/getClassAll'
  return $axios.fPost(url, data)
}

// 获取管辖班级列表
export function getClassListCampus (data) {
  const url = '/class/getCampusClass'
  return $axios.fPost(url, data)
}

// 批量切换直播间
export function switchLuBatch (data) {
  const url = '/class/live/switchLuBatch'
  return $axios.fPost(url, data)
}

// 获取在教班级列表
export function getClassListTeacher (data) {
  const url = '/class/getClassByTeacher'
  return $axios.fPost(url, data)
}

// 更新班级列表
export function syncRefreshList (data) {
  const url = '/classXgj/refreshList'
  return $axios.fPost(url, data)
}

// 新增班级
export function addClassData (data) {
  const url = '/class/addClass'
  return $axios.fPost(url, data)
}


// 编辑班级
export function editClassData (data) {
  const url = '/class/editClass'
  return $axios.fPost(url, data)
}

// 编辑班级授课课程
export function editClassCourse (data) {
  const url = '/class/editClassCourse'
  return $axios.fPost(url, data)
}

// 删除班级
export function delClass (data) {
  const url = '/class/delClass'
  return $axios.fPost(url, data)
}

// 替换班级教师
export function replaceTeacher (data) {
  const url = '/class/replaceTeacher'
  return $axios.fPost(url, data)
}

// 获取班级老师信息
export function getClassTeacherInfoById (data) {
  const url = '/class/getClassTeacherInfoById'
  return $axios.fGet(url, data)
}

// 获取班级排课信息
export function getLessonInfoByClassId (data) {
  const url = '/classlession/getLessonInfoByClassId'
  return $axios.fGet(url, data)
}

// 获取班级学员信息
export function getClassStudentInfoById (data) {
  const url = '/class/getClassStudentInfoById'
  return $axios.fGet(url, data)
}

// 获取班级学员信息- 带任务状态
export function getClassStudentListWithTaskStatus (data) {
  const url = '/class/getClassStudentListWithTaskStatus'
  return $axios.fGet(url, data)
}

// 获取班级学员信息post （支持分页）
export function getClassStudentInfoByIdWithPage (data) {
  const url = '/class/getClassStudentInfoByIdWithPage'
  return $axios.fPost(url, data)
}

// 获取班级学员动态
export function getClassStudentState (data) {
  const url = '/task/userLog'
  return $axios.fPost(url, data)
}

// 获取排课中的学生列表
export function getLessionStudentMessage (data) {
  const url = '/classlession/getLessonStudent'
  return $axios.fGet(url, data)
}

// 获取排课学生列表-带任务状态
export function getLessonStudentWithTaskStatus (data) {
  const url = '/classlession/getLessonStudentWithTaskStatus'
  return $axios.fGet(url, data)
}

// 申请补课视频
export function setAttachmentToUser (data) {
  const url = '/courseUnit/setAttachmentToUser'
  return $axios.fPost(url, data)
}

// 删除排课学员
export function delLessonStudent (data) {
  const url = '/lesson/delStudent'
  return $axios.fPost(url, data)
}

// 同步本节排课数据
export function SyncClassNormal (data) {
  const url = 'lessonXgj/refreshMember'
  return $axios.fPost(url, data)
}

// 排课关联课节
export function editLesson (data) {
  const url = '/lesson/edit'
  return $axios.fPost(url, data)
}

// 修改学员密码
export function changeStuPass (data) {
  const url = '/user/editStudent'
  return $axios.fPost(url, data)
}

// 班级排课排序
export function reSort (data) {
  const url = '/lesson/reSort'
  return $axios.fPost(url, data)
}

// 获取班级学员动态
export function delLesson (data) {
  const url = '/lesson/del'
  return $axios.fPost(url, data)
}

// 获取任务python提交记录列表
export function getSubmitList (data) {
  const url = '/task/listPythonTaskCode'
  return $axios.fPost(url, data)
}
// 获取活动python提交记录列表
export function getActivitySubmitList (data) {
  const url = '/activity/listSubmit'
  return $axios.fPost(url, data)
}

// 获取课节列表
export function getCourseUnitList (data) {
  const url = '/courseUnit/list'
  return $axios.fPost(url, data)
}

// 新获取课节列表
export function getCourseUnitListOnly (data) {
  const url = '/courseUnit/listOnly'
  return $axios.fPost(url, data)
}

// 获取任务数
export function getTaskCount (data) {
  const url = '/course/taskCount'
  return $axios.fPost(url, data)
}

// 批量开放任务给多个学员
export function mapTaskAndUser (data) {
  const url = '/task/mapTaskAndUser'
  return $axios.fPost(url, data)
}

// 获取课程目录课节列表（新）
export function getInfo (data) {
  const url = '/courseUnit/getInfo'
  return $axios.fPost(url, data)
}
// 课程目录课节列表（新）
export function listOnly (data) {
  const url = '/courseUnit/listOnly'
  return $axios.fPost(url, data)
}
// 关联排课到课节（新）
export function mapCourseUnits (data) {
  const url = '/lesson/mapCourseUnits'
  return $axios.fPost(url, data)
}
// 获取关联的课节列表
export function listCourseUnit (data) {
  const url = '/lesson/listCourseUnit'
  return $axios.fPost(url, data)
}
// 开放单个学员
export function switchCheckItem (data) {
  const url = '/task/setTaskUserStatus'
  return $axios.fPost(url, data)
}
// 学员课程
export function getTaskByUser (data) {
  const url = '/task/getTaskByUser'
  return $axios.fPost(url, data)
}
// 获取用户的任务列表 - 课程id
export function getTaskByUserAndCourse (data) {
  const url = '/task/getTaskByUserAndCourse'
  return $axios.fGet(url, data)
}
// 设置任务权限-课程ID
export function setTaskByUserAndCourse (data) {
  const url = '/task/setTaskByUserAndCourse'
  return $axios.fPost(url, data)
}
// 设置学员课程
export function setTaskByUser (data) {
  const url = '/task/setTaskByUser'
  return $axios.fPost(url, data)
}
// 题目批量开放学员
export function updateQuestionState (data) {
  const url = '/teacher/questionlibrary/updateQuestionState'
  return $axios.fPost(url, data)
}
// 获取排行榜接口
export function getSolutionStatisticsByContest (data) {
  const url = '/questionlibrary/getSolutionStatisticsByContest'
  return $axios.fGet(url, data)
}
// 开放全部学员学员
export function switchCheckAll (data) {
  const url = '/task/setTaskUserStatus'
  return $axios.fPost(url, data)
}

// 获取排课列表
export function queryQuestion (data) {
  const url = '/task/listTaskLesson'
  return $axios.fPost(url, data)
}

// 替换班级学员
export function replaceStudent (data) {
  const url = '/class/replaceStudent'
  return $axios.fPost(url, data)
}

// 增加班级学员
export function addStudent (data) {
  const url = '/class/addStudent'
  return $axios.fPost(url, data)
}


// 删除班级学员
export function delStudent (data) {
  const url = '/class/delStudent'
  return $axios.fPost(url, data)
}

// 审核班级
export function classReview (data) {
  const url = '/class/review'
  return $axios.fPost(url, data)
}

// 班级直播间列表
export function getLiveRoomByClass (data) {
  const url = '/class/live/getLiveRoomByClass'
  return $axios.fPost(url, data)
}

// 新增直播间
export function insertClassBliveRoom (data) {
  const url = '/class/live/insertClassBliveRoom'
  return $axios.fPost(url, data)
}

// 编辑直播间
export function updateClassBliveRoomById (data) {
  const url = '/class/live/updateClassBliveRoomById'
  return $axios.fPost(url, data)
}

// 删除直播间
export function deleteClassBliveRoomById (data) {
  const url = '/class/live/deleteClassBliveRoomById'
  return $axios.fGet(url, data)
}

// 直播间进入
export function getWebLiveEnter (data) {
  const url = '/class/live/getWebLiveEnter'
  return $axios.fGet(url, data)
}

// 根据id获取直播间助教码 || 根据id获取直播间信息
export function getLiveRoomtnById (data) {
  const url = '/class/live/getLiveRoomtnById'
  return $axios.fGet(url, data)
}

// 生成参加码
export function createCodeBliveRoom (data) {
  const url = '/class/live/createCodeBliveRoom'
  return $axios.fPost(url, data)
}

// 根据直播间获取关联的排课学生
export function getLessonStudetnByLiveRoom (data) {
  const url = '/class/live/getLessonStudetnByLiveRoom'
  return $axios.fGet(url, data)
}

// 开放直播间
export function switchLiveRoom (data) {
  const url = '/class/live/switchLu'
  return $axios.fGet(url, data)
}

// 班级学生排课
export function getLessonByStudent (data) {
  const url = '/classlession/getLessonByStudent'
  return $axios.fGet(url, data)
}

// 列表（关联了排课的）
export function getListWithLesson (data) {
  const url = '/courseUnit/listWithLesson'
  return $axios.fPost(url, data)
}

// 获取待分班学员列表
export function getListStudentForClass (data) {
  const url = '/class/listStudentForClass'
  return $axios.fPost(url, data)
}

// 班级结课
export function classFinish (data) {
  const url = '/class/finish'
  return $axios.fGet(url, data)
}

// 班级由结业变为未结业
export function classnoFinish (data) {
  const url = 'class/noFinish'
  return $axios.fGet(url, data)
}

// 调班
export function changeClass (data) {
  const url = '/class/changeClass'
  return $axios.fPost(url, data)
}

// 退班
export function leaveClass (data) {
  const url = '/class/leaveClass'
  return $axios.fGet(url, data)
}

// 调课
export function changeLesson (data) {
  const url = '/lesson/changeClass'
  return $axios.fPost(url, data)
}

// 上课点名
export function signLesson (data) {
  const url = '/lesson/signLessonParam'
  return $axios.fPost(url, data)
}

// 获取可调入的排课列表
export function listLessonForJoin (data) {
  const url = '/lesson/listLessonForJoin'
  return $axios.fPost(url, data)
}

// 获取班级教师列表
export function getListTeacher (data) {
  const url = '/class/listTeacher'
  return $axios.fGet(url, data)
}

// 批量排课
export function addBat (data) {
  const url = '/lesson/addBat'
  return $axios.fPost(url, data)
}

// 老师的排课列表
export function getListLessonTeacher (data) {
  const url = '/lesson/listLessonTeacher'
  return $axios.fPost(url, data)
}

// 获取班级上课进度
export function getProgress (data) {
  const url = '/class/getProgress'
  return $axios.fGet(url, data)
}

// 获取可操作校区列表
export function getMyCampus (data) {
  const url = '/campus/getMyCampus'
  return $axios.fGet(url, data)
}

// 获取课程评价列表
export function teacherLessonList (data) {
  const url = '/teacherLesson/list'
  return $axios.fPost(url, data)
}

//老师对学生的评价
export function teacherAppraise (data) {
  const url = '/lesson/comment/appraise'
  return $axios.fPost(url, data)
}

//老师对学生的评价维度
export function getScopes (data) {
  const url = '/lesson/comment/getScopes'
  return $axios.fPost(url, data)
}

// 获取成员变动列表
export function getListMemberLog (data) {
  const url = '/class/listMemberLog'
  return $axios.fPost(url, data)
}


// 批阅python代码
export function reviewPythonTaskCode (data) {
  const url = '/task/reviewPythonTaskCode'
  return $axios.fPost(url, data)
}

// 获取GOC代码列表
export function getGocList (data) {
  const url = '/task/gocList'
  return $axios.fPost(url, data)
}

// 手动设置课消状态
export function setCost (data) {
  const url = '/classlession/setCost'
  return $axios.fGet(url, data)
}

//
export function setListByLoginNames (data) {
  const url = '/user/listByLoginNames'
  return $axios.fPost(url, data)
}
// 设置学员查看编程提交时间
export function setMinHistoryTime (data) {
  const url = '/task/setMinHistoryTime'
  return $axios.fPost(url, data)
}

// 查看学员编程限制提交时间
export function listMinHistoryTime (data) {
  const url = '/task/listMinHistoryTime'
  return $axios.fPost(url, data)
}

export function getAgoraToken(data) {
  const url = '/live/getAgoraToken'
  return $axios.fGet(url, data)
}

